/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/mat-dialog.scss';
@import './styles/mat-menu.scss';
@import './styles/mat-select.scss';
@import './styles/mat-tooltip.scss';
@import './styles/mat-autocomplete.scss';
// Vendor
@import 'swiper/swiper.scss';

@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/a11y-prebuilt.css';

$psv-main-background: #222222;
$psv-loader-color: rgba(255, 255, 255, 0.6);
$psv-loader-width: 100px;
@import 'node_modules/@photo-sphere-viewer/core/index.scss';
// @import './styles/font.scss';

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji';
    @apply bg-gray-700 text-base font-normal text-white;
}

.grecaptcha-badge {
    visibility: hidden;
}
