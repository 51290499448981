.mat-mdc-menu-content {
    @apply py-0 #{!important};
}
.mdc-list-item__primary-text {
    @apply flex flex-row;
}
.mat-mdc-menu-panel {
    @apply min-h-0 rounded bg-gray-600 text-white #{!important};
}

.mat-mdc-menu-item-text {
    @apply min-h-0 flex flex-row  #{!important};
}
