.mat-mdc-select {
    @apply rounded-2xl bg-gray-900 py-2 #{!important};
}

.mat-mdc-select-min-line {
    @apply overflow-hidden text-ellipsis #{!important};
}

.mat-mdc-optgroup-label {
    @apply min-h-[32px] #{!important};
}

.mat-mdc-optgroup-label .mdc-list-item__primary-text {
    @apply overflow-hidden text-ellipsis text-gray-500 hover:text-gray-500 #{!important};
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
    @apply overflow-hidden text-ellipsis text-blue-500 hover:text-blue-600 #{!important};
}

.mdc-list-item__primary-text {
    @apply overflow-hidden text-ellipsis text-white hover:text-gray-300 #{!important};
}

.mat-mdc-select-value {
    @apply overflow-hidden text-ellipsis px-3 text-sm text-white #{!important};
}

.mat-mdc-select-arrow {
    color: rgba(255, 255, 255, 1);
}

.mat-mdc-select-arrow-wrapper {
    @apply p-2 #{!important};
}

// .mat-mdc-select-panel {
//     @apply min-w-full rounded-2xl bg-gray-900  #{!important};
// }
.mat-mdc-option {
    @apply min-h-[32px] hover:bg-gray-800 #{!important};
}

// .mat-mdc-select-disabled .mat-mdc-select-value {
//     color: rgba(100, 100, 100, 1);
// }

.mdc-list-item--disabled .mdc-list-item__primary-text {
    @apply overflow-hidden text-ellipsis text-white #{!important};
}

.mat-mdc-form-field-subscript-wrapper {
    @apply hidden;
}

.mdc-text-field {
    @apply px-0 #{!important};
}

.mdc-line-ripple {
    @apply hidden #{!important};
}